.placeholder-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.placeholder-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

/* Adjust the input field to display selected file name */
.ui.action.input input[type="text"] {
  pointer-events: none;
  background-color: white;
}

/* Hide the default file input button */
.ui.action.input input[type="file"] {
  display: none;
}

.logout-button {
  padding: 0;
  text-decoration: underline;
  color: white;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.desaturate {

  filter: grayscale(50%);
}



.custom-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-grow: 1
}

.custom-grid {
  display: flex;
}

.embed-container {
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  position: relative;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1
}

.full-width {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.flex {
  display: flex;
  flex-grow: 1
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

body {
  /*background-color: #fff5f5 !important;*/
  background-color: #f5f5f7 !important;
  /* Replace with your desired color */
  font-size: 1.2em !important;
  color: #1d1d1f !important;

}

textarea,
label {
  font-size: 1.2em !important;

}

.alt-background-color {

  background-color: #FED7E9;

}


.alt-background-color2 {

  background-color: #ecebf4;

}

.alt-background-color3 {

  background-color: #dedff0;

}




.container-padding {
  padding-top: "2em";
  padding-bottom: "2em";
}

.secondary-color {
  color: #37445d;
}

.dark-grey {
  color: #424242;
}

@media (max-width: 500px) {

  /* Media query for mobile viewport */
  .mainheader {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .header2 {
    font-size: 1.5rem !important;
  }

  .header3 {
    font-size: 1.5rem !important;
  }
}

.textShadow {
  text-Shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

@media (min-width: 501px) {

  /* Media query for desktop viewport */
  .mainheader {
    font-size: 5rem !important;
    font-weight: bold;


  }

  .header2 {
    font-size: 2.5rem !important;
  }

  .header3 {
    font-size: 2rem !important;
  }
}




.image-viewer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;

}

.image-viewer {
  position: relative;
  width: 98%;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.image-viewer-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image-viewer-content img {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  border-radius: 2%;
}

.zoomable {
  cursor: zoom-in;
}

.zoomed {
  cursor: zoom-out;
  max-width: none !important;
  max-height: none !important;
  width: auto !important;
  height: auto !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: auto;
}



input {
  font-size: 16px !important;
  /* Set a specific font size to prevent zooming */
  /* transform: scale(1); Reset any scaling applied by the automatic zoom */
}

.bold-list .list>.item {
  font-weight: bold;
}

.bold-item {
  font-weight: bold;
}





@media only screen and (max-width: 767px) {
  .grid-column-1 {
    order: 1;
  }
}

@media only screen and (max-width: 767px) {
  .grid-column-2 {
    order: 2;
  }
}


@media only screen and (max-width: 767px) {
  .grid-row-1 {
    order: 1;
  }
}

@media only screen and (max-width: 767px) {
  .grid-row-2 {
    order: 2;
  }
}


.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: #0074d9;
  /* Change this color to your desired link color */
  text-decoration: underline;
  cursor: pointer;
}


.centered-container {
  display: flex;
  align-items: left;
  /* Vertical center alignment */
  justify-content: left;
  /* Horizontal center alignment */
  font-size: 1.5em;
  padding-left: 0.1em;
  vertical-align: left;
  /* Note: vertical-align doesn't center vertically within a div */
}