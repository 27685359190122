.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.image-grid-train {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 20px;
}

.image-item {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  /* Maintain aspect ratio for square images */
  position: relative;
  overflow: hidden;
}

.image-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio and fill the container */
}



.image-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}