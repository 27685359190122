.table-of-contents.sticky {
    position: sticky;
    top: 8em;
  }


  .blog-post h1 {
font-size: 3em;


  }

  .blog-list h1 {
    font-size: 3em;
    
    
      }
      

  .image-container {
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 1em;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    
    
  }

  .underline-link {
    text-decoration: none;
    position: relative;
  }
  
  .underline-link::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(65,131,196); /* Customize the color as needed */
  }
  
  .underline-text {
    position: relative;
    display: inline-block;
    padding-bottom: 1px;
    font-variant: all-small-caps;
    font-size: 1.2em;
  }
  